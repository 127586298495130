<template>
  <section v-if="license">
    <b-nav tabs>
      <b-nav-item :to="{ name: 'Administration.Authentications.Standard' }">{{
        $t("AUTH_METHODS.STANDARD_METHODS")
      }}</b-nav-item>
      <b-nav-item :to="{ name: 'Administration.Authentications.Custom' }">{{
        $t("AUTH_METHODS.CUSTOM_METHODS")
      }}</b-nav-item>
      <b-nav-item :to="{ name: 'Administration.Authentications.Log' }">{{
        $t("LOGG")
      }}</b-nav-item>
    </b-nav>
    <router-view :license="license"></router-view>
  </section>
</template>
<script>
export default {
  props: ["license"],
  data() {
    return {};
  },
};
</script>
<style></style>
