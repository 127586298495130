var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.license
    ? _c(
        "section",
        [
          _c(
            "b-nav",
            { attrs: { tabs: "" } },
            [
              _c(
                "b-nav-item",
                {
                  attrs: {
                    to: { name: "Administration.Authentications.Standard" },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("AUTH_METHODS.STANDARD_METHODS")))]
              ),
              _c(
                "b-nav-item",
                {
                  attrs: {
                    to: { name: "Administration.Authentications.Custom" },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("AUTH_METHODS.CUSTOM_METHODS")))]
              ),
              _c(
                "b-nav-item",
                {
                  attrs: { to: { name: "Administration.Authentications.Log" } },
                },
                [_vm._v(_vm._s(_vm.$t("LOGG")))]
              ),
            ],
            1
          ),
          _c("router-view", { attrs: { license: _vm.license } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }